import React from "react";

const Footer = () => {
    return(
        <footer>
             <div className="box_aliance">
                <img src={require("../assets/logo.png")} alt="" />
             </div>
            <div className="contact">
                <div className="box_contact">
                    <strong>LOS ANGELES</strong>
                    <a href="mailto:info@mdsdigital.com">
                        info@mdsdigital.com
                    </a>
                </div>
                <div className="box_contact">
                    <strong>BOGOTA</strong>
                    <a href="mailto:info-co@mdsdigital.com">
                        info-co@mdsdigital.com
                    </a>
                </div>
            </div>
            <div className="social">
                <ul className="social-nav-bar">
                    <li>
                        <a href="https://www.facebook.com/weareMDS/" rel="noreferrer">
                            <svg id="facebook" xmlns="http://www.w3.org/2000/svg" width="15.964" height="15.867" viewBox="0 0 15.964 15.867">
                                <path id="Icon_awesome-facebook" data-name="Icon awesome-facebook" d="M16.526,8.544A7.982,7.982,0,1,0,7.3,16.429V10.852H5.269V8.544H7.3V6.786a2.816,2.816,0,0,1,3.015-3.105,12.284,12.284,0,0,1,1.787.156V5.8H11.092a1.154,1.154,0,0,0-1.3,1.247v1.5h2.214l-.354,2.307H9.791v5.578A7.985,7.985,0,0,0,16.526,8.544Z" transform="translate(-0.563 -0.563)" fill="#fff"/>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://co.linkedin.com/company/mds-digital" rel="noreferrer">
                            <svg id="linkedin" xmlns="http://www.w3.org/2000/svg" width="16.744" height="16" viewBox="0 0 16.744 16">
                                <path id="Icon_zocial-linkedin" data-name="Icon zocial-linkedin" d="M-.18,2.075A1.74,1.74,0,0,1,.386.741,2.075,2.075,0,0,1,1.856.216,2.013,2.013,0,0,1,3.295.733a1.823,1.823,0,0,1,.566,1.39,1.7,1.7,0,0,1-.549,1.293,2.079,2.079,0,0,1-1.487.533H1.808A1.978,1.978,0,0,1,.369,3.416,1.791,1.791,0,0,1-.18,2.075ZM.03,16.216V5.42H3.618v10.8H.03Zm5.576,0H9.194V10.188a2.327,2.327,0,0,1,.129-.873,2.344,2.344,0,0,1,.687-.929,1.756,1.756,0,0,1,1.156-.38q1.81,0,1.81,2.44v5.77h3.588v-6.19A5.2,5.2,0,0,0,15.432,6.4a3.859,3.859,0,0,0-2.99-1.236A3.668,3.668,0,0,0,9.194,6.955v.032H9.178l.016-.032V5.42H5.606q.032.517.032,3.216t-.032,7.58Z" transform="translate(0.18 -0.216)" fill="#fff"/>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/mdsdigitalagencia/" rel="noreferrer">
                            <svg id="instagram" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M9,6.621a4.614,4.614,0,1,0,4.614,4.614A4.606,4.606,0,0,0,9,6.621Zm0,7.613a3,3,0,1,1,3-3A3,3,0,0,1,9,14.234Zm5.878-7.8A1.076,1.076,0,1,1,13.8,5.357,1.074,1.074,0,0,1,14.874,6.433ZM17.93,7.525a5.325,5.325,0,0,0-1.454-3.77A5.36,5.36,0,0,0,12.706,2.3c-1.486-.084-5.939-.084-7.424,0a5.353,5.353,0,0,0-3.77,1.45A5.343,5.343,0,0,0,.058,7.521c-.084,1.486-.084,5.939,0,7.424a5.325,5.325,0,0,0,1.454,3.77,5.367,5.367,0,0,0,3.77,1.454c1.486.084,5.939.084,7.424,0a5.325,5.325,0,0,0,3.77-1.454,5.36,5.36,0,0,0,1.454-3.77c.084-1.486.084-5.935,0-7.42Zm-1.919,9.014A3.037,3.037,0,0,1,14.3,18.25c-1.185.47-4,.361-5.3.361s-4.124.1-5.3-.361a3.037,3.037,0,0,1-1.711-1.711c-.47-1.185-.361-4-.361-5.3s-.1-4.124.361-5.3A3.037,3.037,0,0,1,3.692,4.22c1.185-.47,4-.361,5.3-.361s4.124-.1,5.3.361a3.037,3.037,0,0,1,1.711,1.711c.47,1.185.361,4,.361,5.3S16.481,15.359,16.011,16.539Z" transform="translate(3.005 0.762)" fill="#fff"/>
                            </svg>
                        </a>
                    </li>
                </ul>
                <span>
                    © Powered by MDS
                </span>
            </div>
        </footer>
    );
        
}

export default Footer;